import React, {Component, useEffect, useState} from 'react';
import styles from './Downloads.module.scss';
import {Icon, Loader} from 'semantic-ui-react';
import {motion, AnimatePresence} from 'framer-motion'
import {useGetRegistryLink} from "../../hooks/api/useGetRegistryLink";
import toast from "react-hot-toast";
import Tooltip from "../../common/Tooltip/Tooltip";
import classNames from "classnames";

const DownloadsRow = ({item, downloadModal, openDownloadModal, id, openModal}) => {

    console.log(item)
    const [visible, setVisible] = useState(false)

    const {data, refetch, isLoading} = useGetRegistryLink({
        pkg: item.pkg,
        version: item.version,
    })

    /*useEffect(() => {
        if (visible && item.hasNpm) {
            refetch()
        }
    }, [visible, refetch, item.hasNpm])*/

    const buildLink = () => {
        if (data && item.hasNpm) {
            return `${process.env.REACT_APP_REGISTRY_URL}/${data.token}/${item.pkg}/${item.version}`
        }
        return ''
    }

    const downloadContainerClassName = classNames('grid border-t-2 bg-white md:gap-0 gap-4', {
        'md:grid-cols-3': item.filename_example && item.supportedOS && item.supportedDevices,
        'md:grid-cols-2': !item.filename_example && (item.supportedOS || item.supportedDevices),
        'grid-cols-1': !item.filename_example && !item.supportedOS && !item.supportedDevices,
    })

    return (
            <motion.div className={'w-full'}>
                <motion.tr className={'flex'}>
                    <td key={'filename' + id} colSpan="5" className={styles.break}>
                        <p className={styles.releaseNotes} onClick={() =>
                            setVisible(prev => !prev)}>
                            {item?.name ? `${item?.name}-v${item.version}` : item.filename}
                        </p>
                    </td>
                    <td key={'version' + id} >{item.version}</td>
                        {item.releaseNotes ?
                            <td key={'release' + id}>
                                <p className={styles.releaseNotes}
                                   onClick={() => openModal(item.releaseNotes)}>
                                    Release Notes
                                </p>
                            </td>
                            :
                            <td key={'release' + id} >N/A</td>
                        }
                    <td key={'download' + id}>
                        <p className={styles.releaseNotes} onClick={() => {
                            if (item?.name) {
                                setVisible(prev => !prev);
                            } else {
                                openDownloadModal(item.filename)
                            }
                            //setVisible(prev => !prev)
                        }}>
                            {item?.name ? 'See details' : 'Download'}
                        </p>
                    </td>
                </motion.tr>
                <AnimatePresence>
                {visible &&
                    <motion.tr
                        initial={{opacity: 0, height: 0}}
                        animate={{opacity: 1, height: 'auto'}}
                        exit={{opacity: 0, height: 0, overflow: 'hidden'}}
                        transition={{
                            stiffness: 170,
                            damping: 26,
                            mass: 1
                        }}
                        className={downloadContainerClassName}>
                        <div
                            className={'bg-white flex flex-col items-center justify-center text-center cursor-pointer group gap-4 py-4'}
                            onClick={() => {
                                openDownloadModal(item.filename)
                            }}>
                            {downloadModal.loading && downloadModal.filename === item.filename ?
                                <Loader size='big' active inline/> :
                                <Icon name='download' size={'huge'} className={'group-hover:text-[#35b7fc]'}/>
                            }
                            <div className={'flex flex-col'}>
                                <p className={'text-lg'}>Download standalone plugin</p>
                                <p className={'text-sm'}>The latest version of the SDK, ready to install.</p>
                            </div>
                        </div>
                        {item.filename_example &&
                            <div
                                className={'bg-white flex flex-col items-center justify-center text-center cursor-pointer group gap-4'}
                                onClick={() => {
                                    openDownloadModal(item.filename_example)
                                }}>
                                {downloadModal.loading && downloadModal.filename === item.filename_example ?
                                    <Loader size='big' active inline/> :
                                    <Icon name='download' size={'huge'} className={'group-hover:text-[#35b7fc]'}/>
                                }
                                <div className={'flex flex-col'}>
                                    <p className={'text-lg'}>Download plugin + Example app</p>
                                    <p className={'text-sm'}>Latest version of the SDK, example included</p>
                                </div>
                            </div>}
                        <div className={'bg-white grid md:grid-cols-2 pt-2 gap-8 md:gap-4 py-4'}>
                            <div className={'flex flex-col md:items-start items-center'}>
                                <p className={'text-lg font-bold'}>Qualified iOS/iPad OS Versions</p>
                                <div>
                                    {item.supportedOS && item.supportedOS.map((os, index) => <p key={index}
                                                                                                className={'text-sm'}>{os}</p>)}
                                </div>
                            </div>
                            <div className={'flex flex-col  md:items-start items-center'}>
                                <p className={'text-lg font-bold'}>Supported Devices</p>

                                <div className={'grid grid-cols-2'}>
                                    {item.supportedDevices && item.supportedDevices.map((os, index) => <p key={index}
                                                                                                          className={'text-sm'}>{os}</p>)}
                                </div>
                            </div>
                        </div>
                        {/*{item.hasNpm && <div className={'bg-white flex flex-col items-center justify-center text-center'}>
                            <Icon name='npm' size={'huge'}/>
                            <div className={'flex flex-col items-center'}>
                                <p className={'text-lg'}>Install with npm</p>
                                {isLoading ? <Loader size='tiny' active inline /> : <p className={'text-sm bg-gray-100 p-2 overflow-hidden truncate w-1/2 cursor-pointer'} onClick={() => {
                                    toast.success('Copied to clipboard')
                                    navigator.clipboard.writeText(`yarn add  ${buildLink()}`)
                                }}>
                                    <Tooltip label={'Click to copy to clipboard'}>
                                        <div className={'truncate p-1 text-red-500 font-extrabold text-base'}>yarn add {buildLink()}</div>
                                    </Tooltip>
                                </p>}
                            </div>
                        </div>}*/}
                    </motion.tr>}
                </AnimatePresence>
            </motion.div>
    )
}
export default DownloadsRow;
